import '../css/base.min.css';
// IMPORTING ELEMENTS
import {useEffect} from "react";
import {CardElement, Title} from "./Elements";

export function PageMenuHandbook(){
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        document.title = 'Handbook';
        tg.BackButton.onClick((() => window.location.assign('/helpful/')));
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick(() => window.location.assign('/helpful/'));
    }, []);
    return(
        <div className="main-block">
            <Title title="HandBook"/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Об агентстве'
                         subtitle='Коротко о нас' path='/helpful/handbook/about'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Структура'
                         subtitle='Люди в компании' path='/helpful/handbook/structure'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Ценности'
                         subtitle='Что важно для нас' path='/helpful/handbook/worth'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Формат работы'
                         subtitle='Как мы работаем и где' path='/helpful/handbook/work_format'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Отдых'
                         subtitle='Как мы отдыхаем' path='/helpful/handbook/vacation'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Инструменты'
                         subtitle='Что используем в работе' path='/helpful/handbook/tools'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Карьерный рост'
                         subtitle='Вверх и ввысь!' path='/helpful/handbook/growth'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Реферальная программа'
                         subtitle='Посоветуй нас друзьям' path='/helpful/handbook/referral'/>

        </div>
    );
}